<template>
  <div class="wrapper-main__content">
    <div class="form-content pb-4" style="padding-top: 10px">
      <VocabularyTitle class="mb-4" :title="$t('retroScale.uploadRetroScale')" />
      <div class="border p-4">
        <form class="w-300px">
          <b-form-group :label="$t('channelModal.year')" label-for="input-1">
            <MultiSelect
              v-model="selectedYear"
              label="id"
              track-by="id"
              :options="yearsList"
              class="w-300px"
              :placeholder="$t('channelModal.pickYear')"
              :allow-empty="false"
              @input="getScale"
            >
            </MultiSelect>
          </b-form-group>
          <b-form-group :label="$t('holdings.holding')" label-for="input-1">
            <MultiSelect
              v-model="holding"
              label="name"
              track-by="id"
              :options="holdingsList ?? []"
              class="w-300px"
              :placeholder="$t('holdings.selectHolding')"
              :allow-empty="false"
              @input="getScale"
            >
            </MultiSelect>
            <!-- <b-form-select v-model="holdingId">
              <option v-for="a in holdings.data" :key="a.id" :value="a.id">{{ a.name }}</option>
            </b-form-select> -->
          </b-form-group>
          <b-form-group :label="$t('table.advertiser') + ' (' + $t('table.optional') + ')'" label-for="input-1">
            <MultiSelect
              v-model="advertiser"
              label="name"
              track-by="id"
              :options="advertisersList ?? []"
              class="w-300px"
              :placeholder="$t('table.selectAdvertiser')"
              @input="getScale"
            >
            </MultiSelect>
          </b-form-group>

          <b-form-group v-if="$checkPermissions('retro_bonus_scale.upload')" :label="$t('table.selectFile')" label-for="input-1">
            <b-form-file
              v-model="file"
              :placeholder="$t('table.selectFileOrDrop')"
              :drop-placeholder="$t('table.dropPlaceholder')"
              :browse-text="$t('table.browse')"
              accept=".xlsx, .xls"
              required
            ></b-form-file>
          </b-form-group>
          <input type="submit" value="Submit" class="hidden-submit" />

          <div v-show="holding?.id != null && selectedYear?.id != null">
            <SpinnerLoader v-show="RBScaleStatus == 'loading'" :loading="RBScaleStatus" />
            <div v-if="RBScaleStatus == 'success' && RBScale != null" class="mb-3 text-danger">
              <BIconExclamationTriangle />
              {{ $t('retroScale.scaleWasUploaded') }}.
              <u class="cursor-pointer" @click="showScale">
                {{ $t('table.browse') }}
              </u>
            </div>
          </div>

          <b-form-group v-if="$checkPermissions('retro_bonus_scale.upload')">
            <b-button :disabled="$v.$invalid || isBusy" @click="upload()"> Save </b-button>
          </b-form-group>
        </form>
      </div>
    </div>
    <b-modal id="modal-scale" ref="modal-scale" size="md" hide-footer scrollable>
      <div v-if="RBScaleStatus == 'success'" class="table table-responsive h-100 m-0 p-0">
        <table class="table table-sm small table-bordered sticky-headers" style="table-layout: fixed">
          <thead>
            <tr>
              <th>
                {{ $t('table.budget') }}
              </th>
              <th>
                {{ $t('table.discount') }}
              </th>
            </tr>
          </thead>

          <tbody class="overflow-hidden">
            <tr v-for="(row, index) in RBScale.data.retro_bonus_scale_details" :key="index">
              <td class="text-right">{{ toFixedAndSpace(row.budget) }}</td>
              <td class="text-right">{{ (+row.discount).toFixed(2) }}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { BFormFile } from 'bootstrap-vue';
import VocabularyTitle from '@/components/VocabularyTitle';
import errorsHandler from '@/utils/errorsHandler';
import { required, minValue } from 'vuelidate/lib/validators';
import MultiSelect from '../components/MultiSelect';
import SpinnerLoader from '@/components/SpinnerLoader';
import { BIconExclamationTriangle } from 'bootstrap-vue';
import { getRetroBonusScale } from '@/api/apiServices';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
export default {
  name: 'UploadRetroScale',
  components: { VocabularyTitle, BFormFile, MultiSelect, SpinnerLoader, BIconExclamationTriangle },
  props: {
    advertiser_id: { type: [String, Number], default: undefined },
    holding_id: { type: [String, Number], default: undefined },
    year: { type: [String, Number], default: new Date().getFullYear() },
  },
  data() {
    return {
      holding: null,
      advertiser: null,
      file: null,
      selectedYear: null,
      isBusy: false,
    };
  },
  validations: {
    selectedYear: { required },
    holding: { required },
    file: { required },
  },
  computed: {
    ...mapGetters({
      advertisersList: 'getAdvertisersList',
      yearsList: 'getYear',
      holdingsList: 'getHoldingsList',
      RBScale: 'getRBScale',
      RBScaleStatus: 'getRBScaleStatus',
    }),
  },
  created() {
    document.title = this.$i18n.t('retroScale.uploadRetroScale') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getData();
  },

  methods: {
    async getData() {
      await Promise.all([
        !this.advertisersList || this.advertisersList.length < 1 ? this.$store.dispatch('GET_ADVERTISERS', { per_page: 2000 }) : undefined,
        !this.yearsList || this.yearsList.length < 1 ? this.$store.dispatch('GET_YEAR', { per_page: 2000 }) : undefined,
        !this.holdingsList || this.holdingsList.length < 1 ? this.$store.dispatch('GET_HOLDINGS') : undefined,
      ]);
      this.holding = this.holdingsList.find((x) => x.id == this.holding_id);
      this.selectedYear = this.yearsList.find((x) => x.id == this.year);
      this.advertiser = this.advertisersList.find((x) => x.id == this.advertiser_id);
    },
    async getScale() {
      if (this.holding?.id == null || this.selectedYear?.id == null) return;
      this.updateQuery();
      let formData = {
        holding_id: this.holding.id,
        year_id: this.selectedYear.id,
      };
      if (this.advertiser) formData['advertiser_id'] = this.advertiser.id;
      document.getElementsByClassName('vue-notification-group')[0].classList.add('d-none');
      await this.$store.dispatch('GET_RETRO_BONUS_SCALE', formData);
    },
    async upload() {
      document.getElementsByClassName('vue-notification-group')[0].classList.remove('d-none');
      this.isBusy = true;
      this.processing = true;
      let formData = new FormData();
      formData.append('holding_id', this.holding.id);
      if (this.advertiser) formData.append('advertiser_id', this.advertiser.id);
      formData.append('year_id', this.selectedYear.id);
      formData.append('retro_bonus_scale_details', this.file);
      await this.$store.dispatch('POST_RETRO_BONUS_SCALE_UPLOAD', {
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('retroScale.retroScaleUploaded'),
          });
          this.processing = false;
          this.$emit('updateData');
        },
        handlerError: (errors) => {
          this.processing = false;
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },
    checkIfValidThenEnter() {
      if (!this.$v.$invalid) this.upload();
    },
    showScale() {
      this.$refs['modal-scale'].show();
    },
    toFixedAndSpace(numb) {
      return toFixedAndSpace(numb);
    },
    updateQuery() {
      setTimeout(() => {
        if (
          this.$route.query.year != this.advertiser?.id ||
          this.$route.query.advertiser_id != this.selectedYear?.id ||
          this.$route.query.holding_id != this.holding?.id
        ) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              advertiser_id: this.advertiser?.id,
              year: this.selectedYear?.id,
              holding_id: this.holding?.id,
            },
          });
        }
      }, 50);
    },
  },
};
</script>
